import { navigate } from 'gatsby';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap'
const AchievementCard = ({ number, heading, className, description, colClass, readMore, key }) => {
    return (
        <>
            <Col className={colClass + ' d-none d-md-block'} key={key}>
                <h1 className={className}>
                    {number}
                </h1>
                <h6>{heading}</h6>
                {description && <p className='text-justify'>{description}</p>}
                {readMore && <Button onClick={() => navigate(readMore)}>Read More</Button>}
            </Col>
            <Col className="d-md-none d-xl-none d-lg-none" key={key}>
                <Row noGutters>
                    <h1 className={className}>
                        {number}
                    </h1>
                </Row>
                <Row noGutters>
                    <h6>{heading}</h6>
                    {description && <p className='text-justify'>{description}</p>}
                    {readMore && <Button onClick={() => navigate(readMore)}>Read More</Button>}
                </Row>
            </Col>
        </>
    );
};

export default AchievementCard;